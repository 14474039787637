import getConfig from 'next/config';
import NextImage from 'next/image';

import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

const cdnLoader = ({ src, width, quality = 80 }) => {
  const params = [`width=${width},quality=${quality}`];
  const { origin, pathname } = new URL(src);

  return `${origin}/cdn-cgi/image/${params}${pathname}`;
};

const Image = ({
  src,
  placeholderSrc = '/images/placeholder.jpeg',
  alt = '',
  ...props
}) => {
  const { publicRuntimeConfig } = getConfig();

  const [url, setUrl] = useState(src ?? placeholderSrc);

  if (isEmpty(url)) {
    return null;
  }

  if (src?.includes('.svg')) {
    return <img src={src} alt={alt} loading="lazy" {...props} />;
  }

  const shouldUseCfImages =
    publicRuntimeConfig.useCfImages?.toLowerCase() === 'true' &&
    !url.startsWith('/');

  return (
    <NextImage
      loader={shouldUseCfImages ? cdnLoader : undefined}
      src={
        url.startsWith('/') || shouldUseCfImages
          ? url
          : `/api/imageproxy?url=${encodeURIComponent(src)}`
      }
      onError={() => {
        setUrl(placeholderSrc);
      }}
      alt={alt}
      {...props}
    />
  );
};

export default Image;
